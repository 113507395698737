<template>
  <validation-observer ref="simpleRules">
    <component>

      <b-card v-if="session > 0">

        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon
            icon="PlusCircleIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Tambah Mapping Divisi
          </h4>
        </div>

        <!-- Form: Personal Info Form -->
        <b-form class="mt-1">
          <b-row>

            <!-- Field: Bisnis Unit -->
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
            <validation-provider
              #default="{ errors }"
              name="Bisnis Unit"
              rules="required"
            >
              <b-form-group
                label="Bisnis Unit"
                label-for="bu"
                :state="errors.length > 0 ? false:null"
              >
              <v-select
                v-model="userDataInfo.div_bu"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :options="buOptions"
                :clearable="false"
                input-id="bu"
                placeholder="Pilih Bisnis Unit"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            </b-col>

            <!-- Field: Divisi -->
            <b-col
              cols="12"
              md="6"
              lg="6"
            >
              <b-form-group
                label="Divisi"
                label-for="divisi"
              >
              <validation-provider
                #default="{ errors }"
                name="Divisi"
                rules="required"
              >
                <!-- <b-form-input
                  id="so_code"
                  v-model="userDataInfo.div_desc"
                  type="text"
                  placeholder="input Divisi"
                  :state="errors.length > 0 ? false:null"
                /> -->
                <v-select
                  v-model="userDataInfo.div_desc"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="divOptions"
                  :clearable="false"
                  input-id="divisi"
                  placeholder="Pilih Divisi"
                />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Aktif -->
            <!-- <b-col
              cols="12"
              md="2"
              lg="2"
            >
              <b-form-group
                label="Aktif"
                label-for="aktif"
                label-class="mb-1"
              >
                <b-form-radio-group
                  id="menu_active"
                  v-model="userDataInfo.div_status"
                  :options="aktifOptions"
                />
              </b-form-group>
            </b-col> -->
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>

              <b-button
                variant="outline-secondary"
                :to="{ name: 'apps-mapping-divisi-list'}"
              >
                Back
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>

    </component>
  </validation-observer>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import { ref, onUnmounted, onMounted } from '@vue/composition-api' 
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  codeVueMultiBasic, codeVueMultiIcon, codeMultiPush, codeVueSelectionLimit, codeVueDir,
} from './code'
import { codeVariant } from './codetoast'
import router from '@/router'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      emailValue: '',
      name: '',
      required,
      email,
    }
  },
  methods: {
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const params = new URLSearchParams()
          params.append('param', 'add_divisi') 
          params.append('div_bu', JSON.stringify(this.userDataInfo.div_bu))
          params.append('div_name', JSON.stringify(this.userDataInfo.div_desc))
          params.append('div_status', this.userDataInfo.div_status)
          params.append('created_by', localStorage.getItem('emp_id'))
          const result = await axios.post(`${useAppConfig().AppUrl}mst_mapping_divisi/add`, params)

          if (result.data.message === 'Berhasil menambah data') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Berhasil Disimpan',
                variant: 'success',
              },
            })
            this.$router.push('/apps/mapping-divisi/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Gagal Disimpan',
                variant: 'danger',
              },
            })
            console.log(result)
          }
        }
      })
    },
  },
  setup() {
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })

    const userDataInfo = ref({
      div_bu : '',
      div_desc : '',
      div_status : 1,
    })

    const aktifOptions = [
      { text: 'Ya', value: 1 },
      { text: 'Tidak', value: 0 },
    ]

    const buOptions = ref([])
    const divOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AppUrl}global/listcombo`)
      const dataBU = result.data[0].bu
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataBU.length; i < len; i++) {
        combo.push({ label: dataBU[i].nama_bu, value: dataBU[i].id_bu })
        // combo1.push({ label: dataDivisi[i].div_desc, value: dataDivisi[i].div_name})
      }
      buOptions.value = combo

      const resultDiv = await axios.get(`${useAppConfig().AppUrl}global/listcombo`)
      const dataDiv = resultDiv.data[0].divisi
      // console.log(dataDiv);
      const comboDiv = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataDiv.length; i < len; i++) {
        comboDiv.push({ label: dataDiv[i].div_desc, value: dataDiv[i].div_name })
        // combo1.push({ label: dataDivisi[i].div_desc, value: dataDivisi[i].div_name})
      }
      divOptions.value = comboDiv
    })

    return {
      session,
      codeVueMultiBasic,
      codeVueMultiIcon,
      codeMultiPush,
      codeVueSelectionLimit,
      codeVueDir,
      aktifOptions,
      userDataInfo,
      buOptions,
      divOptions,
      codeVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>

</style>
